import React, { ReactElement, useState } from 'react';
import { createStyles, Grid, StyleRules, Theme, WithStyles, withStyles } from '@material-ui/core';

import { ConsultantApplyingBiddingProjectDialog } from '../../components/Dialog';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    root: {
      maxWidth: '80%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      margin: 'auto',
      paddingTop: theme.spacing(1),
      paddingBottom: '7%',
    },

    bottomButton: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
    deleteButton: {
      justifyContent: 'end',
    },
    dataGridTable: {
      height: 200,
      width: '100%',
    },
    childrenItem: {
      background: '#ffffff',
      boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
      borderRadius: '3px',
      padding: '10px 0',
      margin: '10px auto',
      minWidth: '850px',
    },
    classBorder: {
      // border: '1px solid red',
      margin: '10px 0',
      lineHeight: '56px',
    },
    borders: {
      position: 'relative',
      border: '1px solid #a2a294',
      margin: '10px 0',
      height: '56px',
    },
    titlePosition: {
      padding: '0 6px',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '12px',
      position: 'absolute',
      top: '-10px',
      left: '6px',
      backgroundColor: '#FFFFFF',
    },
    cardWidth: {
      width: '100%',
      height: '56px',
      paddingTop: '8px',
    },
    itemML: {
      lineHeight: '42px',
    },
    salaryBorder: {
      padding: '10px',
      background: '#f5f5f5',
    },
    buttonPosition: {
      margin: '20px',
    },
    borderMargin: {
      margin: '10px 0',
    },
    borderMarginBox: {
      margin: '10px 0',
      border: '1px solid rgb(64, 64, 64,0.3)',
      borderRadius: '3px',
      padding: '20px',
    },
    borderMarginTwo: {
      height: '56px',
    },
    iconLineHeight: {
      margin: '30px 0',
    },
    settlement: {
      margin: '10px 50px 10px 0',
    },
  });

const publishProjectView = ({ classes, onClose }: Props): ReactElement => {
  const [isCancelDialog, setIsCancelDialog] = useState(false);

  return (
    <>
      <Grid xs={8} className={classes.childrenItem} container justifyContent="center">
        <h2>账单信息</h2>
        <Grid item xs={11} className={classes.borderMarginBox} container>
          <Grid item xs={11} className={classes.borderMargin}>
            <h4>项目名称：傅通咨询</h4>
          </Grid>
          <Grid item xs={11} className={classes.borderMargin}>
            款项：傅通平台开发
          </Grid>
          <Grid item xs={11} className={classes.borderMargin}>
            时间：2024-4-3
          </Grid>
          <Grid item xs={11} className={classes.borderMargin}>
            说明：每月项目结算
          </Grid>
          <Grid item xs={11} className={classes.borderMargin}>
            收款人：homsi
          </Grid>
          <Grid item xs={11} className={classes.borderMargin}>
            结算人：Joseph
          </Grid>
          <Grid item xs={11} className={classes.borderMargin}>
            <h4>金额：￥20000</h4>
          </Grid>
        </Grid>
      </Grid>

      <ConsultantApplyingBiddingProjectDialog
        isOpen={isCancelDialog}
        handleEdit={() => {
          setIsCancelDialog(false);
          onClose ? onClose() : '';
        }}
        handleDialogClose={() => {
          setIsCancelDialog(false);
          onClose ? onClose() : '';
        }}
      ></ConsultantApplyingBiddingProjectDialog>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
  showView?: boolean;
  onClose?: Function;
  editData?: boolean;
  enterBidding?: boolean;
  showComment?: boolean;
  adminShowView?: boolean;
}

export default withStyles(styles)(publishProjectView);
